import React, { ReactNode, useEffect } from 'react';
import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import { PageContext } from '../typing/Page';
import { LangContextProvider } from './LangContext';
import reboot from '../styles/reboot';
import type from '../styles/type';
import utils from '../styles/utils';
import { border, fontSize, fontWeight, gutter, spacer } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import Header from './Header';
import Seo from './Seo';
import ArrowTop from '../img/arrow_top.module.svg';
import { resetButton } from '../styles/mixins';
import { graphql, useStaticQuery } from 'gatsby';
// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';

const StyledContainer = styled.div`
	width: 100%;
	padding-right: ${gutter.full};
	padding-left: ${gutter.full};
	margin: 0 auto;
	overflow: hidden;

	${breakpointUp('md')} {
		padding-right: ${gutter.md.full};
		padding-left: ${gutter.md.full};
	}

	${breakpointUp('xxxl')} {
		width: 1648px;
	}
`;

const StyledMain = styled.main`
	margin-bottom: ${spacer['12']};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['15']};
	}
`;

const StyledFooter = styled.footer`
	border-top: ${border.full};
	padding: ${spacer['7']} 0;
`;

const StyledFooterTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

const StyledFooterButtonTop = styled.button`
	${resetButton()};
	font-size: ${fontSize.xs};
	font-weight: ${fontWeight.bold};

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
	}

	> svg {
		margin-left: ${spacer['5']};
	}
`;

const StyledFooterTitle = styled.strong`
	font-size: ${fontSize.md};
	margin-bottom: ${spacer['6']};

	${breakpointUp('md')} {
		font-size: ${fontSize.lg};
	}

	${breakpointUp('lg')} {
		margin-bottom: ${spacer['1']};
	}
`;

const StyledInfo = styled.div`
	display: flex;
	flex-direction: column;

	${breakpointUp('lg')} {
		flex-direction: row;
	}

	> p,
	> a {
		margin-bottom: ${spacer['1']};
		font-size: ${fontSize.xs};

		${breakpointUp('md')} {
			margin-bottom: 0;
			margin-right: ${spacer['8']};
			font-size: ${fontSize.sm};
		}
	}
`;

type Props = {
	path: string;
	children: ReactNode;
	pageContext: PageContext;
	useSeo?: boolean;
};

const Layout: React.FC<Props> = ({ path, children, pageContext, useSeo = true }) => {
	useEffect(() => {
		smoothscroll.polyfill();
	}, []);

	const data = useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
		query Layout {
			translations: allFile(filter: { name: { in: ["cs", "en"] }, extension: { eq: "json" } }) {
				nodes {
					name
					childTranslationsJson {
						copyright
						address
						top
					}
				}
			}
		}
	`);

	const translations = data.translations.nodes.find(node => node.name === pageContext.lang);

	const scroolToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<LangContextProvider lang={pageContext.lang}>
			<Global styles={() => css(reboot + type + utils)} />
			{useSeo && (
				<Seo
					title={pageContext.title}
					description={pageContext.description}
					lang={pageContext.lang}
				/>
			)}
			<StyledContainer>
				<Header
					path={path}
					lang={pageContext.lang}
					langs={pageContext.langs}
					structure={pageContext.structure}
					pageVersions={pageContext.pageVersions}
				/>
				<StyledMain>{children}</StyledMain>
				<StyledFooter>
					<StyledFooterTitleContainer>
						<StyledFooterTitle>Kibala s.r.o.</StyledFooterTitle>
						<StyledFooterButtonTop onClick={scroolToTop}>
							{translations?.childTranslationsJson?.top}
							<ArrowTop />
						</StyledFooterButtonTop>
					</StyledFooterTitleContainer>
					<StyledInfo>
						<p>{translations?.childTranslationsJson?.address}</p>
						<a href="mailto:info@kibala.cz">info@kibala.cz</a>
						<p>{translations?.childTranslationsJson?.copyright}</p>
					</StyledInfo>
				</StyledFooter>
			</StyledContainer>
		</LangContextProvider>
	);
};

export default Layout;
