import React from 'react';
import { getRemSize, resetButton } from '../styles/mixins';
import { border, transition } from '../styles/variables';
import styled from '@emotion/styled';

type StyledCloseProps = {
	isActive: boolean;
};

const StyledClose = styled.button`
	${resetButton()};
	position: relative;
	width: ${getRemSize(32)};
	height: ${getRemSize(32)};
	padding: 0;

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		width: ${getRemSize(32)};
		height: 0;
		right: ${getRemSize(-4)};
		border-bottom: ${border.width} solid ${border.inverse.color};
		transition: transform ${transition.toggleNav.duration} ${transition.toggleNav.timingFunction} ${({ isActive }: StyledCloseProps) => (isActive ? '.3s' : '0s')};
	}

	&::before {
		transform: rotate(${({ isActive }: StyledCloseProps) => (isActive ? '45deg' : '0deg')});
	}

	&::after {
		transform: rotate(${({ isActive }: StyledCloseProps) => (isActive ? '-45deg' : '0deg')});
	}
`;

type Props = {
	isActive: boolean;
	onClick: () => void;
};

const HeaderNavClose: React.FC<Props> = ({ isActive, onClick }) => {
	return (
		<StyledClose
			onClick={onClick}
			isActive={isActive}
			aria-label="Close"
		/>
	);
};

export default HeaderNavClose;
