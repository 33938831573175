import React, { createContext, ReactNode } from 'react';

type Props = {
	children: ReactNode;
	lang: string;
};

const LangContext = createContext('');

export const LangContextProvider: React.FC<Props> = ({ children, lang }) => {
	return <LangContext.Provider value={lang}>{children}</LangContext.Provider>;
};

export const useLangContext = (): string => React.useContext(LangContext);
