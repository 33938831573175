import React from 'react';
import { css } from '@emotion/core';
import { border, spacer, transition, color, fontSize } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import Link from './Link';
import { getRemSize } from '../styles/mixins';

const linkCss = (isActive?: boolean) => css`
	display: block;
	position: relative;
	padding: ${spacer['4']} ${spacer['5']};
	font-weight: bold;
	font-size: ${fontSize.md};
	color: ${color.inverse.link};

	${breakpointUp('md')} {
		padding: ${spacer['1']} ${spacer['2']};
		color: ${color.link};
		font-size: ${fontSize.base};
	}

	${breakpointUp('lg')} {
		padding-left: ${spacer['5']};
		padding-right: ${spacer['5']};
	}

	&::after {
		content: '';
		position: absolute;
		width: ${getRemSize(4)};
		height: ${getRemSize(4)};
		left: ${getRemSize(6)};
		bottom: 50%;
		margin-bottom: ${getRemSize(-2)};
		border-radius: ${getRemSize(2)};
		transform: scale(${isActive ? 1 : 0});
		transition: transform ${transition.hover.duration} ${transition.hover.timingFunction};
		background-color: ${color.inverse.text};

		${breakpointUp('md')} {
			width: auto;
			height: 0;
			left: ${spacer['2']};
			right: ${spacer['2']};
			bottom: 0;
			margin-bottom: 0;
			border-bottom: ${border.full};
		}

		${breakpointUp('lg')} {
			left: ${spacer['5']};
			right: ${spacer['5']};
		}
	}

	&:hover,
	&:focus {
		color: ${color.inverse.linkHover};

		${breakpointUp('md')} {
			color: ${color.linkHover};
		}
	}

	&:hover {
		${breakpointUp('md')} {
			&::after {
				transform: scale(1);
			}
		}
	}
`;

type Props = {
	to: string;
	title: string;
	isActive?: boolean;
	hasLangPrefix?: boolean;
};

const HeaderLink: React.FC<Props> = ({ to, title, isActive, ...props }) => {
	return (
		<Link
			to={to}
			css={linkCss(isActive)}
			{...props}
		>
			{title}
		</Link>
	);
};

export default HeaderLink;
