import React from 'react';
import { PageVersion } from '../typing/Page';
import styled from '@emotion/styled';
import HeaderLink from './HeaderLink';
import { breakpointUp } from '../styles/mq';
import { spacer } from '../styles/variables';

const StyledNavList = styled.ul`
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;

	${breakpointUp('md')} {
		margin-left: ${spacer['8']};
		margin-right: ${spacer['8']};
	}

	${breakpointUp('lg')} {
		margin-left: ${spacer['11']};
		margin-right: ${spacer['11']};
	}
`;

type Props = {
	pageVersions: PageVersion[];
	lang: string;
};

const HeaderLangsSwitcher: React.FC<Props> = ({ lang, pageVersions }) => {
	return (
		<StyledNavList>
			{pageVersions.map(pageVersion => {
				return (
					<li key={pageVersion.lang}>
						<HeaderLink
							to={pageVersion.path}
							title={pageVersion.lang.toUpperCase()}
							hasLangPrefix={false}
							isActive={pageVersion.lang === lang}
						/>
					</li>
				);
			})}
		</StyledNavList>
	);
};

export default HeaderLangsSwitcher;
