import React from 'react';
import { PageStructure } from '../typing/Page';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';
import HeaderLink from './HeaderLink';

const StyledNavList = styled.ul`
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;

	${breakpointUp('md')} {
		flex-direction: row;
	}
`;

type Props = {
	path: string;
	structure: PageStructure[];
};

const HeaderNav: React.FC<Props> = ({ path, structure }) => {
	const pathSlug = path.replace(/^\/?[a-z]{2}/, '');
	return (
		<nav>
			<StyledNavList>
				{structure.map(item => {
					if (item.isInNav) {
						return (
							<li key={item.slug}>
								<HeaderLink
									to={item.slug}
									title={item.title}
									isActive={pathSlug === item.slug || pathSlug.indexOf(item.slug) === 0}
								/>
							</li>
						);
					}
				})}
			</StyledNavList>
		</nav>
	);
};

export default HeaderNav;
