import React, { ReactNode } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useLangContext } from './LangContext';

type Props = {
	to: string;
	hasLangPrefix?: boolean;
	children: ReactNode;
	onMouseEnter?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	onMouseLeave?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	className?: string;
};

const Link: React.FC<Props> = ({ to, children, onMouseEnter, onMouseLeave, className, hasLangPrefix = true, ...props }) => {
	const lang = useLangContext();

	return (
		<GatsbyLink
			to={`${hasLangPrefix ? `/${lang}` : ''}${to}`}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={className}
			{...props}
		>
			{children}
		</GatsbyLink>
	);
};

export default Link;
