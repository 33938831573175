import React from 'react';
import { getRemSize, resetButton } from '../styles/mixins';
import { border, transition, gutter } from '../styles/variables';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';

const StyledToggler = styled.button`
	${resetButton('flex')};
	flex-direction: column;
	justify-content: space-between;
	width: ${getRemSize(32)};
	height: ${getRemSize(20)};
	padding: 0;

	${breakpointUp('md')} {
		display: none;
	}
`;

const StyledTogglerItem = styled.span`
	display: block;
	width: ${getRemSize(32)};
	height: 0;
	border-bottom: ${border.full};
	transition: transform ${transition.toggleNav.duration} ${transition.toggleNav.timingFunction};
`;

const styledTogglerCss = (isActive: boolean) => css`
	> span {
		transform: translate(${isActive ? `calc(${getRemSize(32)} + ${gutter.full})` : '0'}, 0);

		${Array(3)
		.fill(null)
		.map(
			(v, i) => `
			&:nth-of-type(${i + 1}) {
				transition-delay: ${i * transition.toggleNav.togglerItemDelay}ms;
			}
		`
		)}
	}
`;

type Props = {
	isActive: boolean;
	onClick: () => void;
};

const HeaderNavToggler: React.FC<Props> = ({ isActive, onClick }) => {
	return (
		<StyledToggler
			onClick={onClick}
			css={styledTogglerCss(isActive)}
			aria-label="Menu"
		>
			<StyledTogglerItem />
			<StyledTogglerItem />
			<StyledTogglerItem />
		</StyledToggler>
	);
};

export default HeaderNavToggler;
