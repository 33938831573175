import React, { useState } from 'react';
import { PageStructure, PageVersion } from '../typing/Page';
import styled from '@emotion/styled';
import { header, color, zIndex, gutter, spacer, transition } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import Logo from './Logo';
import HeaderNav from './HeaderNav';
import HeaderLangsSwitcher from './HeaderLangsSwitcher';
import HeaderNavToggler from './HeaderNavToggler';
import Link from './Link';
import HeaderNavClose from './HeaderNavClose';

const StyledHeader = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${header.height};
	margin-bottom: ${spacer['8']};

	${breakpointUp('md')} {
		height: ${header.md.height};
		margin-bottom: ${spacer['11']};
	}
`;

type StyledNavContainerProps = {
	isNavOpen: boolean;
};

const StyledNavContainer = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transform: translate(${({ isNavOpen }: StyledNavContainerProps) => (isNavOpen ? '0' : '-100%')}, 0);
	transition: transform ${transition.toggleNav.duration} ${transition.toggleNav.timingFunction} ${({ isNavOpen }: StyledNavContainerProps) => (isNavOpen ? transition.toggleNav.duration : '0s')};
	background-color: ${color.inverse.bg};
	z-index: ${zIndex.nav};

	${breakpointUp('md')} {
		position: static;
		flex-direction: row;
		background-color: ${color.bg};
		transform: none;
	}
`;

const StyledNavContainerHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${header.height};
	padding: 0 ${gutter.full};
	margin-bottom: ${spacer['5']};

	${breakpointUp('md')} {
		display: none;
	}
`;

const StyledLangsSocialContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: auto;
	margin-bottom: ${spacer['1']};

	${breakpointUp('md')} {
		margin-bottom: 0;
		justify-content: flex-start;
	}
`;

type Props = {
	path: string;
	lang: string;
	langs: string[];
	structure: PageStructure[];
	pageVersions: PageVersion[];
};

const Header: React.FC<Props> = ({ path, lang, structure, pageVersions }) => {
	const [ isNavOpen, setIsNavOpen ] = useState(false);

	return (
		<StyledHeader>
			<Link
				to="/"
				aria-label="Kibala"
			>
				<Logo />
			</Link>
			<StyledNavContainer isNavOpen={isNavOpen}>
				<StyledNavContainerHeader>
					<Logo fill={color.inverse.text} />
					<HeaderNavClose
						onClick={() => setIsNavOpen(false)}
						isActive={isNavOpen}
					/>
				</StyledNavContainerHeader>
				<HeaderNav
					path={path}
					structure={structure}
				/>
				<StyledLangsSocialContainer>
					<HeaderLangsSwitcher
						lang={lang}
						pageVersions={pageVersions}
					/>
					{/*<HeaderSocial />*/}
				</StyledLangsSocialContainer>
			</StyledNavContainer>
			<HeaderNavToggler
				isActive={isNavOpen}
				onClick={() => setIsNavOpen(prevState => !prevState)}
			/>
		</StyledHeader>
	);
};

export default Header;
